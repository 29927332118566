<template>
  <div class="main_box">
    <div class="header_box" :class="isMobile?'mobile':''">
      <!-- 1:3 -->
      <div style="width: 25%">
        <img
          src="../../public/images/home/headerleft.png"
          style="width: 100%;"
        />
      </div>
      <div class="heade_menu_box">
        <div class="heade_menu_pc">
          <template v-if="!isMobile">
            <div
              class="opn_item"
              :class="curRouteName === item.name?'active':''"
              v-for="(item, index) in navList"
              :key="index"
              @click="toPath(item.name)"
            >
              {{ item.tit }}
              <span></span>
            </div>
          </template>
        </div>
        <div class="heade_menu_mobile" v-if="isMobile">
          <div class="menu" @click="changeMenu">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="select_opt" :class="menu ? 'menuActive' : ''">
      <div class="ops_item"
      v-for="(item, index) in navList" 
      :key="index" 
      :class="curRouteName === item.name?'active':''"
      @click="toPath(item.name)"
      >{{ item.tit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myHeader",
  data() {
    return {
      menu: false,
      isMobile: false,
      curRouteName:"home",
      navList: [
        {
          tit: "首页",
          name: "home",
        },
        {
          tit: "服务项目",
          name: "products",
        },
        {
          tit: "产品优势",
          name: "advantages",
        },
        {
          tit: "招募技师",
          name: "recruitArti",
        },{
          tit: "招商合作",
          name: "partner",
        },
        {
          tit: "关于我们",
          name: "aboutUs",
        },
        {
          tit: "服务商城",
          name: "shoppingMall",
        }
      ],
      timer: null,
    };
  },
  mounted() {
    if (document.body.clientWidth < 560) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.curRouteName = this.$router.history.current.name
  },
  computed: {
    size() {
      return this.$store.state.size;
    },
  },
  watch: {
    size() {
      if (this.size < 560) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.menu = false
      }
    },
    $route(){
      this.curRouteName = this.$router.history.current.name
    }
  },
  methods: {
    changeMenu() {
      this.menu = !this.menu;
    },
    toPath(name,i) {
      if(name === "shoppingMall"){
        location.href = "https://www.goodyue520.com/dyUserh5/index.html#/"
      }else{
        this.$router.push({ name });
      }
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.main_box {
  position: relative;
}
.header_box {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.mobile{
  height: 100px;
}
.heade_menu_box {
  display: flex;
  width: 75%;
}
.heade_menu_pc {
  width: 80%;
  display: flex;
}
.header_center {
  flex: 2;
  display: flex;
}

.opn_item {
  position: relative;
  font-size: 8px;
  font-weight: 600;
  margin: 0 10px;
  color: #7f7f7f;
  line-height: 24px;
  cursor: pointer;
}
.active>span{
  content: "";
  width: 100%;
  height: 1px;
  background-color: #f18101;
  position: absolute;
  top: 50%;
  left: 0;
}
.opn_item:hover {
  color: #f18101;
}
.opn_item:before,
.opn_item:after {
  content: "";
  width: 0;
  height: 1px;
  background-color: #f18101;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.4s;
}

.opn_item:hover:before {
  width: 50%;
  left: 0px;
}

.opn_item:hover:after {
  width: 50%;
  right: 0px;
}
.header_right {
  display: flex;
  align-content: center;
  margin-right: 60px;
}
.menu > div {
  width: 60px;
  height: 6px;
  background-color: #f18101;
  position: relative;
  margin: 16px 0;
}
.select_opt {
  width: 45%;
  background-color: white;
  margin-left: 20px;
  height: 0;
  overflow: hidden;
  transition: all 0.4s;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 99;
}
.menuActive {
  width: 45%;
  height: 580px;
  transition: all 0.4s;
}
.header_left {
  height: 100%;
  flex: 1;
}
.header_left > img {
  width: 210px;
  /* height: 52px; */
}
.ops_item{
  font-size: 0.36rem;
  margin:0.2rem 0;
  font-weight: 600;
  margin: 0.5rem;
  border-bottom: 1px solid #dedfe0;
}
.active{
  color: #f18101;
}
</style>